import React, { useState, useEffect } from "react";
import Table from "../../EntryFile/datatable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import "react-select2-wrapper/css/select2.css";
import { adminApiInstance } from "../../api/axios";

const Inventory = () => {
  const [loads, setLoads] = useState([]);
  const [deletedIds, setDeletedIds] = useState(() => {
    const saved = localStorage.getItem("deletedIds");
    return saved ? JSON.parse(saved) : [];
  });

  const fetchLoads = async () => {
    try {
      const response = await adminApiInstance.get("/getLoads?type=indicators");
      console.log(response.data, "Dataa");
      const filteredLoads = response.data.filter(
        (item) => item.palletsCount <= 5 && !deletedIds.includes(item._id)
      );
      console.log(filteredLoads, "Filtered Data");
      setLoads(filteredLoads);
    } catch (error) {
      console.error("Error fetching loads:", error);
    }
  };

  useEffect(() => {
    fetchLoads();
  }, [deletedIds]);

  const handleReorderClick = (id) => {
    const updatedDeletedIds = [...deletedIds, id];
    setDeletedIds(updatedDeletedIds);
    localStorage.setItem("deletedIds", JSON.stringify(updatedDeletedIds));
    toast.success("Reorder Done", {
      position: "top-right",
    });
  };

  const columns = [
    {
      title: "Load Name",
      dataIndex: "loadNumber",
      sorter: (a, b) => a.loadNumber.length - b.loadNumber.length,
    },
    {
      title: "SKU Number",
      dataIndex: "skuNumber",
      sorter: (a, b) => a.skuNumber.length - b.skuNumber.length,
    },
    {
      title: "Load Cost",
      dataIndex: "loadCost",
      sorter: (a, b) => a.loadCost - b.loadCost,
    },
    {
      title: "Pallets in",
      dataIndex: "palletsCount",
      sorter: (a, b) => a.palletsCount - b.palletsCount,
    },
    {
      title: "Pallets out",
      dataIndex: "remainingPalletsCount",
      sorter: (a, b) => a.remainingPalletsCount - b.remainingPalletsCount,
      render: (text, record) => {
        if (record.remainingPalletsCount <= 5) {
          return (
            <span style={{ color: "red" }}>
              {text} (Please reorder this load)
            </span>
          );
        }
        return text;
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (text, record) => (
        <span>
          <img
            src="https://png.pngtree.com/png-vector/20191113/ourmid/pngtree-green-check-mark-icon-flat-style-png-image_1986021.jpg"
            alt=""
            style={{ height: "3rem", cursor: "pointer" }}
            onClick={() => handleReorderClick(record._id)}
          />
        </span>
      ),
    },
  ];

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Inventory Indicators Report</h4>
            <h6>Manage your Inventory Report</h6>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <div className="table-top">
              <div className="search-set"></div>
            </div>
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={loads}
                rowKey={(record) => record._id}
              />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Inventory;
