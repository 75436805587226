import React from "react";
import { useLocation } from "react-router-dom";

function PurchaseOrder() {
  const location = useLocation();
  const report = location.state?.report;

  if (!report) {
    // Handle the case when report data is not available
    return <div>Report data not found</div>;
  }

  const formatDate = (dateString) => {
    if (!dateString) {
        return;
    }
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getUTCDate()).padStart(2, '0'); // Add leading zero if needed
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
};

  return (
    <div className="page-wrapper">
      <div className="content">
        <div className="page-header">
          <div className="page-title">
            <h4>Report Details</h4>
            <h6>Full details of a Report</h6>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-8 col-sm-12">
            <div className="card">
              <div
                className="card-body print_single"
                style={{ listStyle: "none", padding: 0 }}
              >
                <div className="productdetails">
                  <ul className="product-bar">
                    <li>
                      <h4>Date</h4>
                      <h6>{formatDate(report?.loadDate)}</h6>
                    </li>
                    <li>
                      <h4>Load Cost</h4>
                      <h6>{report.loadCost.toFixed(2)}</h6>
                    </li>
                    <li>
                      <h4>Load Name</h4>
                      <h6>{report.loadNumber}</h6>
                    </li>
                    <li>
                      <h4>Pallets Count</h4>
                      <h6>{report.palletsCount}</h6>
                    </li>
                    <li>
                      <h4>Pallets Out</h4>
                      <h6>{report.remainingPalletsCount}</h6>
                    </li>
                    <li>
                      <h4>Per Pallet Price</h4>
                      <h6>{report.perPalletCost.toFixed(2)}</h6>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PurchaseOrder;
