import React, { useState } from "react";
import PropTypes from "prop-types";
import { useReactToPrint } from "react-to-print";
import { TfiPrinter } from "react-icons/tfi";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./printStyles.css";

const BrandDetails = ({ item, loadNumber, category }) => {
  const [barcodeOnly, setBarcodeOnly] = useState(false);
  const [numCopies, setNumCopies] = useState(1);
  const [allowPrinting, setAllowPrinting] = useState(true);
  // const [verticalPrint, setVerticalPrint] = useState(false); 
  const brandRef = React.useRef();
  const brandDetailsRef = React.useRef();
console.log('item',item);
  const handlePrintBrand = useReactToPrint({
    content: () => {
      if (!allowPrinting) {
        return null;
      }
      if (barcodeOnly) {
        const tableStat = brandRef.current.cloneNode(true);
        const PrintElem = document.createElement("div");
        for (let i = 0; i < numCopies; i++) {
          PrintElem.appendChild(tableStat.cloneNode(true));
        }
        return PrintElem;
      }
      const tableStat = brandDetailsRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      for (let i = 0; i < numCopies; i++) {
        const copyNumber = i + 1;
        const palletNumbers = `${copyNumber}-${item.totalPallet}`;
        const copyElement = tableStat.cloneNode(true);
        copyElement.querySelectorAll(".pallet-number").forEach((el) => {
          el.textContent = palletNumbers;
        });
        PrintElem.appendChild(copyElement);
      }
      return PrintElem;
    }
  });

  const handleClickPrint = () => {
    if (numCopies > item.totalPallet) {
      toast.error(
        "Please enter copies less than or equal to total pallet count."
      );
      setAllowPrinting(false);
    } else {
      setAllowPrinting(true);
      handlePrintBrand();
    }
  };

  let palletNumbers = "";
  if (item.totalPallet === 1) {
    palletNumbers = "1";
  } else if (item.totalPallet > 1) {
    palletNumbers = `1-${item.totalPallet}`;
  }

  return (
    <div className={` print_single`}>
      <div
        className={`productdetails mb-4`}
        ref={brandDetailsRef}
        style={{ width: "100%" }}
      >
        <ul className="product-bar">
          <li>
            <h4>Load Name</h4>
            <h6>{loadNumber}</h6>
          </li>
          <li>
            <h4>Category</h4>
            <h6>{category}</h6>
          </li>
          <li>
            <h4>Brand Name</h4>
            <h6>{item.brandName}</h6>
          </li>
          <li>
            <h4>Pallet Number</h4>
            <h6 className="pallet-number">{palletNumbers}</h6>
          </li>
          <li>
            <h4>Total Pallet</h4>
            <h6>{item.totalPallet}</h6>
          </li>
          <li>
            <h4>Total Price</h4>
            <h6>{item.totalPrice.toLocaleString()}</h6>
          </li>
          <li>
            <h4>SKU Code</h4>
            <h6>{item.skuCode}</h6>
          </li>
        </ul>
        
        <div
          ref={brandRef}
          className="barcode_view mb-4"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: "1px solid #dfdfdf"
          }}
        >
          <img src={item.barcodeImage} alt="barcode" />
        </div>
      </div>
      <div
        style={{
          marginTop: "10px",
          padding: "0 10px",
          display: "flex",
          alignItems: "center",
          flexDirection: "column"
        }}
      >
        <div className="d-flex justify-content-between align-items-center w-100 mb-3">
          <input
            checked={barcodeOnly}
            onChange={() => setBarcodeOnly(!barcodeOnly)}
            type="checkbox"
          />
          <label style={{ marginLeft: "5px" }}>Barcode Only</label>
          <input
            type="number"
            min="1"
            value={numCopies}
            onChange={(e) => setNumCopies(parseInt(e.target.value))}
            style={{ marginLeft: "10px", width: "50px" }}
          />
          <label style={{ marginLeft: "5px" }}>Copies</label>
        </div>
        <div className="d-flex justify-content-between align-items-center w-100">
          {/* <input
            checked={verticalPrint}
            onChange={() => setVerticalPrint(!verticalPrint)}
            type="checkbox"
          />
          <label style={{ marginLeft: "5px" }}>Vertical Print</label> */}
          <div style={{ marginLeft: "auto" }}>
            <button
              onClick={handleClickPrint}
              style={{
                border: "1px solid #3498db",
                borderRadius: "5px",
                background: "#3498db",
                color: "#fff",
                cursor: "pointer",
                display: "flex",
                alignItems: "center",
                padding: "5px 10px",
                marginBottom: "5px"
              }}
            >
              <TfiPrinter style={{ marginRight: "5px" }} />
              Print
            </button>
          </div>
        </div>
      </div>
      <ToastContainer />
      </div>
  );
};

BrandDetails.propTypes = {
  item: PropTypes.shape({
    brandName: PropTypes.string.isRequired,
    totalPallet: PropTypes.number.isRequired,
    totalPrice: PropTypes.number.isRequired,
    skuCode: PropTypes.string.isRequired,
    barcodeImage: PropTypes.string.isRequired,
  }).isRequired,
  loadNumber: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired
};

export default BrandDetails;
