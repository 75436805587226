import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './popup.css';
import { toast } from 'react-toastify';

const Revertpallet = ({ show,onClose, onUpdate, palletData }) => {
  const [count, setCount] = useState();

  const handleCountChange = (e) => {
    const value = e.target.value;
    // Remove leading zeros if the value is not empty or zero
    const newValue =
      value === "" || parseInt(value, 10) === 0 ? value : parseInt(value, 10) || "";
    setCount(newValue);
  };

  const handleUpdate = () => {
    if (Number(count) > Number(palletData?.palletsCount))
      return toast.error("Pallet Count should be less than Pallets and Balance Out Pallets");
    onUpdate(palletData, count);
    onClose();
  };
  if (!show) return null;

  return (
    <div className="popup d-flex justify-content-center align-items-center">
      <div className="popup-content border rounded p-3 bg-light">
        <h5 className="text-warning">revert pallet</h5>
        <hr className="border-warning" />
        <form>
          <div className="mb-3">
            <label className="form-label">HPC_TEST</label>
            <p>{palletData?.loadNumber}</p>
          </div>
          <div className="mb-3">
            <label className="form-label">pallet count:</label>
            <input type="number" className="form-control" placeholder="Enter count" onChange={handleCountChange} />
          </div>
          <div className="d-flex justify-content-end">
            <button type="button" className="btn btn-outline-warning me-2" onClick={onClose}>Cancel</button>
            <button type="submit" className="btn btn-warning" onClick={handleUpdate}>Submit</button>
          </div>
        </form>
      </div>
    </div>
  );
};

Revertpallet.propTypes = {
  show: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  palletData: PropTypes.object
};

export default Revertpallet;
