import React, { useState } from "react";
import { Modal, Button } from "antd";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import './productList.css';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const UpdateDetailsModal = ({ onCancel, onUpdate, data }) => {
  const [count, setCount] = useState();
  const [palletsDate, setPalletsDate] = useState(new Date());

  const handleCountChange = (e) => {
    const value = e.target.value;
    // Remove leading zeros if the value is not empty or zero
    const newValue =
      value === "" || parseInt(value, 10) === 0 ? value : parseInt(value, 10) || "";
    setCount(newValue);
  };

  const handleUpdate = () => {
    if (Number(count) > Number(data?.palletsCount))
      return toast.error("Pallet Count should be less than Pallets and Balance Out Pallets");
    onUpdate(data, count, palletsDate, data.palletsCount);
    onCancel();
  };

  const handleDateChange = (date) => {
    setPalletsDate(date);
  }

  return (
    <Modal
      visible={true}
      title="Update Pallets Count"
      onCancel={onCancel}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="update" type="primary" onClick={handleUpdate}>
          Update
        </Button>,
      ]}
    >
      <div style={{ marginBottom: "10px" }}>
        <label htmlFor="palletsCount">New Pallets Count:</label>
      </div>

      <div className="input_popup_pallet" style={{display:"flex", justifyContent:"space-between"}}>
      <div>
        <input
          type="number"
          id="palletsCount"
          value={count}
          onChange={handleCountChange}
          placeholder={0}
          style={{padding: "4px", borderRadius:"5px", border: "1px solid black", color: "#333"}}
        />
      <span style={{ marginLeft: "10px" }}>
        <DatePicker
          selected={palletsDate}
          onChange={handleDateChange}
          // minDate={new Date()}
          dateFormat="MM-dd-yyyy"
          style={{ marginTop: "10px", padding:"1.1rem", borderRadius:"10px" }}
        />
      </span>
      </div>

      <div>
      {/* <input type="date" id="date" onChange={(e)=>{setDate(new Date(e.target.value).toISOString())}} name="date" style={{padding: "10px", borderRadius:"5px", border: "1px solid #ccc", color: "#333"}} /> */}

      </div>
      </div>

    </Modal>
  );
};

UpdateDetailsModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

export default UpdateDetailsModal;
