/* eslint-disable react/prop-types */
import React from 'react'
import { Redirect, Route ,Switch} from 'react-router-dom';
import ProductList from './ProductList'
import AddProduct from './AddProduct'
import EditProduct from './EditProduct'
import AddCategory from './AddCategory';

import PrintBarcode from './PrintBarcode';
import ProductDetails from './productDetails';
import AddEmployee from './AddEmployee';
import UserList from './UserList';
import ScanInScanOut from './ScanInScanOut';
import Inventory from './Inventory';
import LoadReport from './LoadReport';
import addCat from './addCat';
import PurchaseOrder1 from './PurchaseOrder';
import PurchaseOrder from './PurchaseOrder1';


const ProductRoute = ({ match }) => (
    <Switch>
        <Redirect exact from={`${match.url}/`} to={`${match.url}/productlist-product`} />
        <Route path={`${match.url}/add-loads`} component={AddProduct} />

        <Route path={`${match.url}/list-loads`} component={ProductList} />
        <Route path={`${match.url}/add-pallets`} component={AddCategory} />
        <Route path={`${match.url}/printbarcode-pallets`} component={PrintBarcode} />
        <Route path={`${match.url}/add-User`} component={AddEmployee} />
        <Route path={`${match.url}/Users-List`} component={UserList} />
        <Route path={`${match.url}/addCategory`} component={addCat} />
        <Route path={`${match.url}/purchaseorderreport`} component={PurchaseOrder1} />
        <Route path={`${match.url}/Load-Report`} component={LoadReport} />
        <Route path={`${match.url}/ScanInScanOut`} component={ScanInScanOut} />
        <Route path={`${match.url}/InventoryIndicators`} component={Inventory} />

        <Route path= {`${match.url}/reportDetail`} component={PurchaseOrder}/>
        <Route path={`${match.url}/editproduct-product`} component={EditProduct} />
        <Route path={`${match.url}/product-details/:id`} component={ProductDetails} />

    </Switch>
)

export default ProductRoute;