import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './printStyles.css';
import { adminApiInstance } from '../../api/axios';

const Categorypopup = ({ handleClose }) => {
  const [categoryName, setCategoryName] = useState("");

  const handleSubmit = async () => {
    try {
      if (!categoryName.trim()) {
        alert("Category name cannot be empty");
        return;
      }

      const response = await adminApiInstance.post("/addCategory", {
        name: categoryName,
      });
      console.log("Category added:", response.data);

      setCategoryName("");
      handleClose();
    } catch (error) {
      console.error("Error adding category:", error);
      alert("Error adding category. Please try again.");
    }
  };
  const handleOutsideClick = (e) => {
    if (e.target.classList.contains('popup-wrapper')) {
      handleClose();
    }
  };

  return (
    <div className="popup-wrapper" onClick={handleOutsideClick}>
      
        <div className="card popup-content">
          <div className="card-body">
            <div className="row">
              <div className="">
                <div className="form-group">
                  <label>Category Name</label>
                  <input
                    type="text"
                    value={categoryName}
                    onChange={(e) => setCategoryName(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
              <div className="col-lg-12">
                <button
                  className="btn btn-submit me-2"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
                <button className="btn btn-cancel" onClick={handleClose}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

Categorypopup.propTypes = {
  handleClose: PropTypes.func.isRequired,
};

export default Categorypopup;
