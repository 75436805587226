import React, {useState } from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import Revertpallet from './revertpallet';
import { adminApiInstance } from '../../api/axios';
// import { convertDate } from '../../utils/utility';

const PalletOutReport = ({ data,fetchData,selectedOption }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [selectedPallet, setSelectedPallet] = useState(null);

  const totalPalletsOut = data.reduce((total, item) => total + item.onDatePalletOut, 0);
  const totalPalletsIn = data.reduce((total, item) => total + item.palletIn, 0);
  const loadCostTotal = data.reduce((total, item) => total + item.total, 0);
  const totalRemainingCost = data.reduce((total, item) => total + (item?.perPalletCost?.toFixed(2)) * (item?.palletIn), 0);

  const formatDate = (dateString) => {
    if (!dateString) return;
    const date = new Date(dateString);
    const year = date.getUTCFullYear();
    const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Add leading zero if needed
    const day = String(date.getUTCDate()).padStart(2, '0'); // Add leading zero if needed
    const formattedDate = `${month}-${day}-${year}`;
    return formattedDate;
  };





  const handleUpdate = async(palletData, count) => {
    // const convertPalletsDate=convertDate(palletData.addedAt);
    console.log('palletData',palletData)
    try {
      await adminApiInstance.post("/updateUsedLoad", {
        load: palletData.loadId,
        remainingPalletsCount: Number(palletData.remainingPalletsCount),
        usedPalletsCount: palletData.onDatePalletOut-count,
        palletsCount:palletData.palletsCount,
        palletsDate: palletData.addedAt,
      });
      fetchData(selectedOption);
    } catch (error) {
      console.error("Error updating pallets count:", error);
    }

  };

  const handleActionClick =(item)=>{
    setSelectedPallet(item);
    setShowPopup(true);
  }


  return (
    <>
      <div className='purchaseTable'>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>Process Date</th>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>Load Name</th>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>Remaining Pallets</th>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>Remaining Cost</th>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>OnDate Pallets Out</th>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>Total Pallets Out</th>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>Pallet Out Cost ($)</th>
              <th style={{ backgroundColor: '#6A5ACD', color: 'white' }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {data.filter((item) => item.onDatePalletOut !== 0).map((item, index) => (
              <tr key={index}>
                <td>{formatDate(item.addedAt)}</td>
                <td>{item.loadNumber}</td>
                <td>{item.palletIn}</td>
                <td>{((item?.perPalletCost)*(item?.palletsCount))?.toFixed(2)}</td>
                <td>{item.onDatePalletOut}</td>
                <td>{item.palletsOut}</td>
                <td>{Math.abs(item.total).toFixed(2)}</td>

                <td>
                  <img
                    src='https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT9Qgdmon9OInbbLHlZlSFwfRJUuCixATDR8w&s'
                    alt=''
                    style={{ height: "2rem", cursor: "pointer" }}
                    onClick={() => handleActionClick(item)}
                  />
                </td>
              </tr>
            ))}
            <tr>
              <td>
                <p className="pallet">Grand Total:</p>
              </td>
              <td></td>
              <td>
                <p className="loadCost">
                  <span style={{ display: 'inline-block', border: '1px solid #ccc', padding: '4px', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>{totalPalletsIn.toFixed(2)}</span>
                </p>
              </td>
              <td>
                <p className="loadCost">
                  <span style={{ display: 'inline-block', border: '1px solid #ccc', padding: '4px', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>${totalRemainingCost.toFixed(2)}</span>
                </p>
              </td>
              <td>
                <p className="loadCost">
                  <span style={{ display: 'inline-block', border: '1px solid #ccc', padding: '4px', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>{totalPalletsOut?.toFixed(2)}</span>
                </p>
              </td>
              <td></td>
              <td>
                <p className="loadCost">
                  <span style={{ display: 'inline-block', border: '1px solid #ccc', padding: '4px', borderRadius: '5px', backgroundColor: '#f0f0f0' }}>${Math.abs(loadCostTotal).toFixed(2)}</span>
                </p>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>

      <Revertpallet show={showPopup} onClose={()=>{setShowPopup(false)}} onUpdate={handleUpdate} palletData={selectedPallet} />
    </>
  );
};

PalletOutReport.propTypes = {
  data: PropTypes.array.isRequired,
  fetchData: PropTypes.array.isRequired,
  selectedOption: PropTypes.array.isRequired,
};

export default PalletOutReport;
