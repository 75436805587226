export const convertDate = (selectedDate, formatEndType) => {
    var originalDate = new Date(selectedDate);
    const weekday = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
    const months = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
    let year = originalDate.getFullYear();
    let month = months[originalDate.getMonth()];
    let date = originalDate.getDate();
    let day = weekday[originalDate.getDay()];
    let endTimeFormat = '';
    if (formatEndType == 'start') {
      endTimeFormat = ' 00:00:00 GMT';
    } else {
      endTimeFormat = ' 23:59:59 GMT';
    }
    let formattedDate = day+", "+date+" "+month+" "+year+endTimeFormat;
    return formattedDate;
};
