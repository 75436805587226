import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes package
import './printStyles.css';
import Select2 from 'react-select2-wrapper';
import { DeleteIcon } from "../../EntryFile/imagePath";
import { adminApiInstance } from '../../api/axios';
import { convertDate } from '../../utils/utility';
import { RxCross2 } from "react-icons/rx";
import Barcode from 'react-barcode';
import { toast } from 'react-toastify';
import html2canvas from 'html2canvas';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';

const EditListLoad = ({ closemodal, record, fetchLoads }) => {
  const [loadData, setLoadData] = useState({
    loadNumber: record.loadNumber,
    loadCost: record.loadCost,
    palletsCount: record.palletsCount,
    category: record.category._id,
    loadDate: dayjs(record.loadDate) ,
    perPalletCost: record.perPalletCost,
    skuNumber: record.skuNumber,
    isBrand: record.isBrand,
    brands: record.brands,
  });
  const [categories, setCategories] = useState([]);

  const handleBrandChange = (index, field, e) => {
    const updatedBrands = [...loadData.brands];
    if (field === "totalPallet") {
      updatedBrands[index]["totalPrice"] =
        (Number(loadData.loadCost) / Number(loadData.palletsCount)) *
        Number(e.target.value);
    }
    updatedBrands[index][field] = e.target.value;
    setLoadData({ ...loadData, brands: updatedBrands });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "loadCost") {
      setLoadData({
        ...loadData,
        [name]: value,
        perPalletCost: (loadData.palletsCount !== 0
          ? value / loadData.palletsCount
          : 0
        ).toFixed(2),
      });
    } else if (name === "palletsCount") {
      setLoadData({
        ...loadData,
        [name]: value,
        perPalletCost: (value !== 0 ? loadData.loadCost / value : 0).toFixed(2),
      });
    } else {
      setLoadData({ ...loadData, [name]: value });
    }
  };
  const handleDateChange = (date) => {
    console.log("Selected date:", date);
    setLoadData({ ...loadData, loadDate: date });
  };
  
  const addBrand = () => {
    setLoadData((prevData) => ({
      ...prevData,
      brands: [
        ...prevData.brands,
        {
          brandName: "",
          totalPallet: "",
          totalPrice: "",
          skuCode: "",
        },
      ],
      skuNumber: "",
    }));
  };
  const deleteBrandField = (index) => {
    const updatedBrands = [...loadData.brands];
    updatedBrands.splice(index, 1);
    setLoadData({ ...loadData, brands: updatedBrands });
  };


  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await adminApiInstance.get("/categories");
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);

  const convertBarcodeToImage = async (ID) => {
    try {
      const barcodeDiv = document.getElementById(ID);
      if (barcodeDiv) {
        const canvas = await html2canvas(barcodeDiv, {
          width: 250,
          height: 135,
        });
        const imageData = canvas.toDataURL("image/png");
        return imageData;
      }
    } catch (error) {
      console.error("Error converting barcode to image:", error);
    }
  };


  const handleSubmit = async (e) => {
    e?.preventDefault();
    let palletsCounts = 0;
    // let key = 0;
    if (
      !(
        loadData.loadNumber &&
        loadData.loadCost &&
        loadData.palletsCount &&
        loadData.category &&
        loadData.loadDate
      )
    ) {
      toast.error("Invalid values");
      return;
    }
    // for (let item of loadData.brands) {
    //   palletsCounts = palletsCounts + Number(item.totalPallet ?? 0);
    //   item["barcodeImage"] = await convertBarcodeToImage(`barcode_${key}`);
    //   key = key + 1;
    //   console.log('barcodeImage',item["barcodeImage"]);

    // }

const brandPromises = loadData.brands.map(async (item,key) => {
  palletsCounts = palletsCounts + Number(item.totalPallet ?? 0);
  item["barcodeImage"] = await convertBarcodeToImage(`barcode_${key}`);
  // key = key+1;
  console.log('barcodeImage', item["barcodeImage"]);
});

await Promise.all(brandPromises);
    // if (!(palletsCounts === 0 || palletsCounts === loadData.palletsCount)) {
    //   toast.error("In Brand Total Pallet Count must be same as Pellets Count");
    //   return;
    // }
    try {
      const convertedDate = await convertDate(loadData.loadDate);
      const barcodeImageData = await convertBarcodeToImage("barcode-container");
      await adminApiInstance.patch(`/updateRemainingPalletsCount/${record._id}`, {
        load: {
          ...loadData,
          barcodeImage: barcodeImageData,
          loadDate: convertedDate,
        },
      });
      toast.success("Load updated successfully");
      fetchLoads();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <>
      <div className="modal-wrapper_Dashboard"></div>
      <div className="modal-container_Dashboard_invoice popup_dashboard editlistload bg-white m-4 p-4 load_popup_brand">

        <div className=' d-flex justify-content-between align-items-center'>
          <div className="page-title">
            <h4>Load List</h4>
            <h6>Manage your loads</h6>
          </div>
          <RxCross2 onClick={closemodal} className=' cursor-pointer fs-2' />
        </div>
        <div className='d-flex g-2'>
          <div className="form-group mt-2 w-100 m-1">
            <label htmlFor="Load Name" className="form-label">Load Name</label>
            <input
              type="text"
              className="form-control"
              name="loadNumber"              
              value={loadData.loadNumber}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group mt-2 w-100 m-1">
            <label htmlFor="Pallet Count" className="form-label">Pallet Count</label>
            <input
              type="text"
              className="form-control"
              name="palletsCount"
              value={loadData.palletsCount}
              onChange={handleInputChange}
            />
          </div>
        </div>

        <div className='d-flex g-2'>
          <div className="form-group mt-2 w-100 m-1">
            <label htmlFor="Load Cost" className="form-label">Load Cost</label>
            <input
              type="text"
              className="form-control"
              name="loadCost"
              value={loadData.loadCost}
              onChange={handleInputChange}
            />
          </div>

          <div className="form-group mt-2 w-100 m-1">
            <label htmlFor="Load Date" className="form-label">Load Date</label>
            <DatePicker
                      selected={loadData.loadDate.toDate()}
                      onChange={handleDateChange}
                      className="form-control"
                      style={{ marginTop: "10px" }}
                    />
          </div>
        </div>

        <div className='form-group mt-2'>
          <label htmlFor="Category" className="form-label">Category</label>
          <Select2
            className="select"
            data={categories.map(category => ({
              id: category._id,
              text: category.name
            }))}            options={{
              placeholder: 'Choose Category',
            }}
            value={loadData.category}
            onChange={(e) => setLoadData({ ...loadData, category: e.target.value })}
          />
        </div>

        {!loadData.isBrand && (
          <div className='d-flex g-2'>
            <div className="form-group mt-2 w-100 m-1">
              <label htmlFor="Load Cost" className="form-label">SKU Code</label>
              <input
                type="text"
                className="form-control"
                name="skuNumber"
                value={loadData.skuNumber}
                onChange={handleInputChange}
              />
            </div>

            <div className="form-group mt-2 w-100 m-1">
              <label htmlFor="Load Cost" className="form-label">Pallet Per Count</label>
              <input
                type="text"
                className="form-control"
                name="perPalletCost"
                value={loadData.perPalletCost}
                // onChange={handleInputChange}
              />
            </div>
          </div>
        )}

        {/* Barcode block is here */}
        {loadData.skuNumber && !loadData.isBrand && (
                  <div className="col-lg-4 col-sm-6 col-12">
                    <div id="barcode-container">
                      <Barcode value={loadData.skuNumber} />
                    </div>
                  </div>
                )}

        <div className="form-group mt-2 d-flex">
          <input
            type="checkbox"
            className="form-check-input m-1"
            id="Is Brand"
            checked={loadData.isBrand}
            onChange={() =>
              setLoadData({
                ...loadData,
                isBrand: !loadData.isBrand,
              })}          />
          <label htmlFor="Is Brand" className="form-label">Is Brand</label>
        </div>

        {loadData.isBrand && loadData.brands.map((brand, index) => (
          <div key={index} className="brand-section">
            <div className='d-flex g-2'>
              <div className="form-group mt-2 w-100 m-1">
                <label htmlFor={`Brand Name ${index}`} className="form-label">Brand Name</label>
                <input
                  type="text"
                  className="form-control"
                  id={`Brand Name ${index}`}
                  value={brand.brandName}
                  onChange={(e) => handleBrandChange(index, 'brandName', e)}
                />
              </div>

              <div className="form-group mt-2 w-100 m-1">
                <label htmlFor={`Total Pallets ${index}`} className="form-label">Total Pallets</label>
                <input
                  type="text"
                  className="form-control"
                  id={`Total Pallets ${index}`}
                  value={brand.totalPallet}
                  onChange={(e) => handleBrandChange(index, 'totalPallet', e)}
                />
              </div>
            </div>
            <div className='d-flex g-2'>
              <div className="form-group mt-2 w-100 m-1">
                <label htmlFor={`Total Price ${index}`} className="form-label">Total Price</label>
                <input
                  type="text"
                  className="form-control"
                  id={`Total Price ${index}`}
                  value={parseFloat(brand.totalPrice).toFixed(2)}
                  // onChange={(e) => handleBrandChange(index, 'totalPrice', e)}
                />
              </div>

              <div className="form-group mt-2 w-100 m-1">
                <label htmlFor={`SKU Code ${index}`} className="form-label">SKU Code</label>
                <input
                  type="text"
                  className="form-control"
                  id={`SKU Code ${index}`}
                  value={brand.skuCode}
                  onChange={(e) => handleBrandChange(index, 'skuCode', e)}
                />
              </div>
            </div>

            {brand.skuCode && loadData.isBrand && (
                          <div className="col-lg-4 col-sm-6 col-12">
                            <div id={`barcode_${index}`}>
                              <Barcode value={brand.skuCode} />
                            </div>
                          </div>
                        )}

            <div className='d-flex justify-content-end'>
              <button type="button" className="btn mb-2" onClick={() => deleteBrandField(index)}>
                <img src={DeleteIcon} alt="Delete" />
              </button>
            </div>
          </div>
        ))}

        {loadData.isBrand && (
          <button type="button" className="btn btn-info mt-2 mb-2" onClick={addBrand}>
            Add Brand
          </button>
        )}

        <div className="col-md-6">
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              handleSubmit();
              closemodal();
              fetchLoads();
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </>
  );
};

// Add propTypes validation for closemodal prop
EditListLoad.propTypes = {
  closemodal: PropTypes.func.isRequired, // Ensure closemodal is a required function prop
  record: PropTypes.string.isRequired,
  fetchLoads: PropTypes.func.isRequired,
};

export default EditListLoad;
